import SiteSwitcher from "../components/siteswitcher"
import { graphql } from "gatsby"

export default SiteSwitcher
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        images {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        tags
        webpages {
          publicURL
        }
        interval
      }
      fields {
        slug
        table
      }
    }
  }
`
