import React, {useEffect,useState} from "react"
import Layout from "./layout"
import Turn from "./turn.js"
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import { graphql } from "gatsby"

export default function Switcher({data}) {
  const post = data.markdownRemark
  console.log(post)
  const pages = post.frontmatter.webpages.map(e => e.publicURL)
  const [deleteIframe,setDeleteIframe] = useState(false);
  const interval = post.frontmatter.interval || 5000
  let count = 0
  let [index,setIndex] = useState(count)
  console.log(`switched to ${pages[index]}`)
  let content = (
    <div id="switcher-content">
      {deleteIframe ? "" : <iframe key={index} title="gaslighting" id="FileFrame" width="100%" height="100%" src={pages[index]}></iframe>}
      {post.frontmatter.images ? <div id="site-switcher-pageturn"><Turn data={data}>
        {data.markdownRemark.frontmatter.images
          .map(image =>image.childImageSharp.gatsbyImageData)
          .map((imData,i) =>
          <div key={i} className="spread">
              <GatsbyImage loading={i < 3 ? "eager" : "lazy"} image={getImage(imData)} alt=""/>
          </div>
          )
        }
      </Turn></div> : null}
    </div>
  )

  useEffect(()=>{
    setIndex(Math.floor(Math.random()*pages.length))
    if(typeof window !== "undefined"){
      setInterval(function(){
        setDeleteIframe(true);
        setTimeout(()=>{
          setDeleteIframe(false);
        },3)
        setIndex(Math.floor(Math.random()*pages.length))
      },window.innerWidth < 480 ? 8000 : interval)
    }
  }, [])
  //remove ^ array?
  return <Layout data={data} children={content}></Layout>
}
